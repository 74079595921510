import { Anchor } from '@sergeimeza/uikit-react'

import { useFetchChallenges } from '../../providers/TochigiProvider'
import { TochigiLayout } from '../layout/TochigiLayout'

import Challenge from '../../assets/images/challenge.png'
import Character from '../../assets/images/character.png'

export function RankingsPage() {
  const { challenges, loading } = useFetchChallenges()

  if (loading) return null

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-80 sm:w-96 mx-auto z-10"
            src={Challenge}
            alt="体力診断ゾーン"
          />
        </div>
      </header>
      <div className="text-2xl font-bold">みんなでチャレンジランキング</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 mt-4 mb-10">
        {challenges
          .filter((challenge) => challenge.challengeType === 'GROUP')
          .map((challenge) => (
            <Anchor
              to={`/rankings/${challenge._id}`}
              className="bg-green-500 text-white h-32 rounded-lg shadow"
            >
              <div className="my-7 flex flex-col items-center">
                <div className="text-xl text-center">
                  {challenge.challengeName}
                </div>
                <div className="bg-white px-8 py-1 mt-2 rounded-full text-green-600">
                  ランキングを見る
                </div>
              </div>
            </Anchor>
          ))}
      </div>
      <div className="text-2xl font-bold">ひとりでチャレンジランキング</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 mt-4 mb-10">
        {challenges
          .filter((challenge) => challenge.challengeType === 'INDIVIDUAL')
          .map((challenge) => (
            <Anchor
              to={`/rankings/${challenge._id}`}
              className="bg-green-500 text-white h-32 rounded-lg shadow"
            >
              <div className="my-7 flex flex-col items-center">
                <div className="text-xl text-center">
                  {challenge.challengeName}
                </div>
                <div className="bg-white px-8 py-1 mt-2 rounded-full text-green-600">
                  ランキングを見る
                </div>
              </div>
            </Anchor>
          ))}
      </div>
    </TochigiLayout>
  )
}
