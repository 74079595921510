import { createContext, useCallback, useEffect, useState } from 'react'

import axios from 'axios'
import firebase from 'firebase/app'

import { useAsync, useLocalStorage } from '@sergeimeza/foundation-react'
import {
  useAuth,
  useSignInAnonymously,
  useSignInWithCustomToken,
} from '@sergeimeza/firebase-react'

import {
  Bending,
  FitnessTest,
  Grip,
  HandballThrow,
  ShuttleRun,
  SideJump,
  SitUps,
  SprintRun,
  StandingJump,
} from '../utils/FitnessTest'
import { InitializeFirebase } from '../services/firebase'

export let TochigiAPI = axios.create({
  baseURL: 'https://api-v2.alpha-pestalozzi.com/tochigi',
})

interface TochigiValue {
  challengeInfo: any
  saveChallengeInfo: (info: any) => Promise<any>
}

const initialState: TochigiValue = {
  challengeInfo: null,
  saveChallengeInfo: async () => {},
}

export const useFetchChallenges = () => {
  const {
    value: challenges,
    loading,
    error,
  } = useAsync(async () => {
    const result = await TochigiAPI.get('/challenges')
    return result.data?.data
  }, [TochigiAPI])

  return { challenges, loading, error }
}

export const useFetchChallengeResults = () => {
  const fetchChallengeResults = useCallback(
    async (challengeId: string, options: any = {}) => {
      let searchParams = new URLSearchParams()
      Object.keys(options).forEach((key) => {
        searchParams.set(key, options[key])
      })
      const result = await TochigiAPI.get(
        `/challenges/${challengeId}/results?${searchParams.toString()}`,
      )

      let results = result.data?.data
      return results
    },
    [TochigiAPI],
  )

  return { fetchChallengeResults }
}

export const usePostChallengeResult = () => {
  const postChallengeResult = useCallback(
    async (challengeId: string, payload) => {
      const result = await TochigiAPI.post(
        `/challenges/${challengeId}/results`,
        { payload },
      )
      return result.data?.data
    },
    [TochigiAPI],
  )

  return { postChallengeResult }
}

export const useDeleteChallengeResult = () => {
  const deleteChallengeResult = useCallback(
    async (challengeId: string, resultId: string) => {
      const result = await TochigiAPI.delete(
        `/challenges/${challengeId}/results/${resultId}`,
      )
      return result.data?.data
    },
    [TochigiAPI],
  )

  return { deleteChallengeResult }
}

export const TochigiContext = createContext<TochigiValue>(initialState)

const { auth } = InitializeFirebase()

export const TochigiProvider = ({ children }) => {
  const [challengeInfo, setchallengeInfo, resetchallengeInfo] = useLocalStorage(
    'CHALLENGE_INFO',
    null,
  )

  async function saveChallengeInfo(info) {
    setchallengeInfo(info)
    return info
  }

  const value: TochigiValue = {
    challengeInfo,
    saveChallengeInfo,
  }

  return (
    <TochigiContext.Provider value={value}>{children}</TochigiContext.Provider>
  )
}
