import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { Button, SelectMenu, Table, Pagination } from '@sergeimeza/uikit-react'

import {
  useFetchChallengeResults,
  useFetchChallenges,
} from '../../providers/TochigiProvider'
import { TochigiLayout } from '../layout/TochigiLayout'
import { useForm } from 'react-hook-form'

import Challenge from '../../assets/images/challenge.png'
import Character from '../../assets/images/character.png'
import SchoolFirst from '../../assets/images/school-first.png'
import SchoolSecond from '../../assets/images/school-second.png'
import SchoolThird from '../../assets/images/school-third.png'
import { useQuery } from '../../hooks/useQuery'

export function RankingPage() {
  let query = useQuery()
  const history = useHistory()
  const { challengeId }: any = useParams()

  const [testYear, setTestYear] = useState(query.get('testYear') ?? 2024)
  const [period, setPeriod] = useState(query.get('period') ?? 1)
  const [schoolGrade, setSchoolGrade] = useState(query.get('schoolgrade') ?? 1)

  const { challenges, loading } = useFetchChallenges()
  const { fetchChallengeResults } = useFetchChallengeResults()

  const [results, setResults] = useState<any[]>([])

  const challenge = useMemo(() => {
    if (!challenges) return
    return challenges.find((challenge) => challenge._id === challengeId)
  }, [challenges])

  useEffect(() => {
    if (!challenge) return
    fetchChallengeResults(challenge._id, {
      testYear:
        Number(query.get('testYear')) > 0
          ? Number(query.get('testYear'))
          : currentTestYear(),
      schoolGrade,
      skip: Number(query.get('page')) * 50,
      gender: query.get('gender') ?? "",
      period,
      limit: 50,
    }).then(setResults)
  }, [challenge])

  const { register, handleSubmit } = useForm({
    defaultValues: {
      testYear:
        Number(query.get('testYear')) > 0
          ? Number(query.get('testYear'))
          : 2024,
      schoolGrade:
        Number(query.get('schoolgrade')) > 0
          ? Number(query.get('schoolgrade'))
          : 1,
      period: Number(query.get('period')) > 0 ? Number(query.get('period')) : 1,
      gender: query.get('gender') ?? ""
    },
    // resolver: yupResolver(resultSchema),
  })

  async function onSubmit(data: any) {
    if (!challenge) return

    setTestYear(data.testYear)
    setPeriod(data.period)
    setSchoolGrade(data.schoolGrade)
    fetchChallengeResults(challenge._id, {
      ...data,
      skip: 0,
      limit: 50,
    }).then(setResults)

    window.location.replace(
      `/rankings/${challengeId}?page=0&schoolgrade=${data.schoolGrade}&testYear=${data.testYear}&period=${data.period}&gender=${data.gender ?? ''}`,
    )
  }

  if (loading) return null

  const columns = (page) => {
    if (isNaN(page)) return []

    return [
      {
        key: 'ranking',
        label: (
          <ruby>
            順位<rt>じゅんい</rt>
          </ruby>
        ),
        value: ['ranking'],
        render: (value) => (
          <Table.Cell>
            {page === 0 && value === 1 ? (
              <img className="w-7" src={SchoolFirst} alt="とちぎくん" />
            ) : page === 0 && value === 2 ? (
              <img className="w-7" src={SchoolSecond} alt="とちぎくん" />
            ) : page === 0 && value === 3 ? (
              <img className="w-7" src={SchoolThird} alt="とちぎくん" />
            ) : (
              `${page * 50 + value}位`
            )}
          </Table.Cell>
        ),
      },
      {
        key: 'schoolName',
        label: (
          <ruby>
            学校名<rt>がっこうめい</rt>
          </ruby>
        ),
        value: ['schoolName'],
        render: (value) => <Table.Cell>{value}</Table.Cell>,
      },
      {
        key: 'period',
        label: (
          <ruby>
            時期<rt>じき</rt>
          </ruby>
        ),
        value: ['period'],
        render: (value) => (
          <Table.Cell>
            {value === 2 ? (
              <ruby>
                後期<rt>こうき</rt>
              </ruby>
            ) : (
              <ruby>
                前期<rt>ぜんき</rt>
              </ruby>
            )}
          </Table.Cell>
        ),
      },
      {
        key: 'schoolGrade',
        label: (
          <ruby>
            学年<rt>がくねん</rt>
          </ruby>
        ),
        value: ['schoolGrade'],
        render: (value) => <Table.Cell>{value}年</Table.Cell>,
      },
      {
        key: 'schoolClass',
        label: (
          <ruby>
            組<rt>くみ</rt>
          </ruby>
        ),
        value: ['schoolClass'],
        render: (value) => <Table.Cell>{value}組</Table.Cell>,
      },
      {
        key: 'teamName',
        label:
          challenge.challengeType === 'GROUP' ? (
            <span>
              グループ
              <ruby>
                名<rt>めい</rt>
              </ruby>
            </span>
          ) : (
            <span>
              <ruby>
                出席番号<rt>しゅっせきばんごう</rt>
              </ruby>
            </span>
          ),
        value: ['teamName'],
        render: (value) => (
          <Table.Cell>
            {challenge.challengeType === 'GROUP' ? value : `${value}番`}
          </Table.Cell>
        ),
      },
      {
        key: 'membersCount',
        label: (
          <ruby>
            人数<rt>にんすう</rt>
          </ruby>
        ),
        value: ['membersCount'],
        render: (value) => (
          <Table.Cell>
            {challenge.challengeType === 'GROUP' ? value : 1}
          </Table.Cell>
        ),
      },
      {
        key: 'value',
        label: (
          <ruby>
            記録<rt>きろく</rt>
          </ruby>
        ),
        value: ['value'],
        render: (value) => (
          <Table.Cell>
            {value}
            {challenge.challengeUnit}
          </Table.Cell>
        ),
      },
      {
        key: 'testDate',
        label: (
          <ruby>
            測定日<rt>そくていび</rt>
          </ruby>
        ),
        value: ['testDate'],
        render: (value) => <Table.Cell>{value}</Table.Cell>,
      },
      {
        key: 'registrationCount',
        label: (
          <ruby>
            登録回数<rt>とうろくかいすう</rt>
          </ruby>
        ),
        value: ['registrationCount'],
        render: (value) => <Table.Cell>{value}</Table.Cell>,
      },
    ]
  }

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-80 sm:w-96 mx-auto z-10"
            src={Challenge}
            alt="体力診断ゾーン"
          />
        </div>
      </header>
      <h1 className="text-lg sm:text-2xl text-gray-700 tracking-widest font-bold text-center">
        {challenge?.challengeName}
      </h1>
      <div className="bg-white my-4 rounded-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-2 px-4">
            <div className="grid grid-cols-2 sm:grid-cols-6 gap-6">
              <div className="col-span-2 sm:col-span-2">
                <SelectMenu
                  label={
                    <ruby>
                      年度<rt>ねんど</rt>
                    </ruby>
                  }
                  name="testYear"
                  selectClassNames="bg-gray-100 border-0"
                  options={[
                    { name: '2021', value: 2021 },
                    { name: '2022', value: 2022 },
                    { name: '2023', value: 2023 },
                    { name: '2024', value: 2024 },
                    { name: '2025', value: 2025 },
                    { name: '2026', value: 2026 },
                    { name: '2027', value: 2027 },
                    { name: '2028', value: 2028 },
                    { name: '2029', value: 2029 },
                    { name: '2030', value: 2030 },
                  ]}
                  register={register('testYear')}
                />
              </div>
              <div className="col-span-2 sm:col-span-2">
                <SelectMenu
                  label={
                    <ruby>
                      時期<rt>じき</rt>
                    </ruby>
                  }
                  name="period"
                  selectClassNames="bg-gray-100 border-0"
                  options={[
                    { name: '前期', value: 1 },
                    { name: '後期', value: 2 },
                  ]}
                  selectedItem={{
                    name: `前期`,
                    value: 1,
                  }}
                  defaultValue={2}
                  register={register('period')}
                />
              </div>
              <div className="col-span-2 sm:col-span-2">
                <SelectMenu
                  label={
                    <ruby>
                      学年<rt>がくねん</rt>
                    </ruby>
                  }
                  name="schoolGrade"
                  selectClassNames="bg-gray-100 border-0"
                  options={[
                    { name: '小学校 1年生', value: 1 },
                    { name: '小学校 2年生', value: 2 },
                    { name: '小学校 3年生', value: 3 },
                    { name: '小学校 4年生', value: 4 },
                    { name: '小学校 5年生', value: 5 },
                    { name: '小学校 6年生', value: 6 },
                  ]}
                  register={register('schoolGrade')}
                />
              </div>
              <div className="col-span-2 sm:col-span-2">
                <SelectMenu
                  label={
                    <ruby>
                      性別<rt>せいべつ</rt>
                    </ruby>
                  }
                  name="gender"
                  selectClassNames="bg-gray-100 border-0"
                  options={[
                    { name: '全て', value: '' },
                    { name: '男子', value: 'MALE' },
                    { name: '女子', value: 'FEMALE' },
                  ]}
                  register={register('gender')}
                />
              </div>
              <div className="flex items-end">
                <Button
                  className="inline-flex justify-center"
                  type="submit"
                  size={Button.size.xl}
                >
                  <div>
                    <ruby>
                      絞<rt>しぼ</rt>
                    </ruby>
                    り
                    <ruby>
                      込<rt>こ</rt>
                    </ruby>
                    む
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {!!!results.length && (
        <div className="mt-10 text-lg tracking-wider text-center">
          まだ
          <ruby>
            記録<rt>きろく</rt>
          </ruby>
          はありません。
        </div>
      )}

      {(query.get('page') ?? '0') === '0' && !!results.length && (
        <div className="flex flex-col items-center my-10">
          <div className="grid grid-cols-1 sm:grid-cols-3 grid-rows-none sm:grid-rows-2 gap-x-0 sm:gap-x-4 gap-y-4 sm:gap-y-4 z-10">
            {results.length > 0 && (
              <div className="col-start-1 sm:col-start-2 flex flex-col items-center">
                <img className="w-28 mb-4" src={SchoolFirst} alt="とちぎくん" />
                <div>
                  <div className="text-center font-bold">
                    {results[0].schoolName}
                  </div>
                  <div className="text-center">
                    {challenge.challengeType === 'GROUP'
                      ? results[0].teamName
                      : `${results[0].schoolClass}組 ${results[0].teamName}番`}
                  </div>
                </div>
              </div>
            )}

            {(query.get('page') ?? '0') === '0' && results.length > 1 && (
              <div className="col-start-1 flex flex-col items-center">
                <img className="w-28" src={SchoolSecond} alt="とちぎくん" />
                <div>
                  <div className="text-center font-bold">
                    {results[1].schoolName}
                  </div>
                  <div className="text-center">
                    {challenge.challengeType === 'GROUP'
                      ? results[1].teamName
                      : `${results[1].schoolClass}組 ${results[1].teamName}番`}
                  </div>
                </div>
              </div>
            )}

            {(query.get('page') ?? '0') === '0' && results.length > 2 && (
              <div className="col:start-1 sm:col-start-3 flex flex-col items-center">
                <img className="w-28" src={SchoolThird} alt="とちぎくん" />
                <div>
                  <div className="text-center font-bold">
                    {results[2].schoolName}
                  </div>
                  <div className="text-center">
                    {challenge.challengeType === 'GROUP'
                      ? results[2].teamName
                      : `${results[2].schoolClass}組 ${results[2].teamName}番`}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {!!results.length && (
        <div className="my-4">
          <Table
            data={results.map((result, index) => ({
              ranking: index + 1,
              key: result._id,
              ...result,
            }))}
            columns={columns(+(query.get('page') ?? '0'))}
          />
        </div>
      )}

      <div className="mt-10">
        <Pagination
          previousText={(query.get('page') ?? '0') === '0' ? '' : '前へ'}
          previousProps={{
            href:
              (query.get('page') ?? '0') === '0'
                ? undefined
                : `/rankings/${challengeId}?testYear=${testYear}&page=${
                    Number(query.get('page')) - 1
                  }&schoolgrade=${schoolGrade}&period=${period}`,
            to: undefined,
          }}
          nextText="次へ"
          nextProps={{
            href:
              results.length == 50
                ? `/rankings/${challengeId}?testYear=${testYear}&page=${
                    Number(query.get('page')) + 1
                  }&schoolgrade=${schoolGrade}&period=${period}`
                : '#',
            to: undefined,
          }}
          pages={[]}
        />
      </div>

      <div className="text-center my-4">
        <ruby>
          同記録<rt>どうきろく</rt>
        </ruby>
        は
        <ruby>
          入力順<rt>にゅうりょくじゅん</rt>
        </ruby>
        で
        <ruby>
          表示<rt>ひょうじ</rt>
        </ruby>
        されます。
        <ruby>
          表彰対象<rt>ひょうしょうたいしょう</rt>
        </ruby>
        は1
        <ruby>
          位<rt>い</rt>
        </ruby>
        〜3
        <ruby>
          位<rt>い</rt>
        </ruby>
        までです。（3
        <ruby>
          位<rt>い</rt>
        </ruby>
        と
        <ruby>
          同記録<rt>どうきろく</rt>
        </ruby>
        の
        <ruby>
          場合<rt>ばあい</rt>
        </ruby>
        はそれらの
        <ruby>
          結果<rt>けっか</rt>
        </ruby>
        も
        <ruby>
          表彰対象<rt>ひょうしょうたいしょう</rt>
        </ruby>
        となります）
      </div>
      <div className="flex flex-col items-center">
        <Button
          buttonType={Button.buttonType.secondary}
          onClick={() => history.replace('/rankings')}
        >
          もどる
        </Button>
      </div>
    </TochigiLayout>
  )
}

function currentTestYear() {
  return getTestYear(new Date())
}

function getTestYear(date: Date) {
  let year = date.getFullYear()
  // 0 to 11
  let month = date.getMonth()
  console.log(year, month)
  return month > 2 ? year : year - 1
}
