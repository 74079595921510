import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { firebaseConfig } from './firebase-config'

export function InitializeFirebase() {
  const app = firebase.apps.length
    ? firebase.app()
    : firebase.initializeApp(firebaseConfig)

  const auth = app.auth()
  const database = app.database()

  return {
    app,
    auth,
    database,
  }
}
