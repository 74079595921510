import { useHistory } from 'react-router'

import { Button, Divider } from '@sergeimeza/uikit-react'

import { TochigiLayout } from '../layout/TochigiLayout'

import Challenge from '../../assets/images/challenge.png'
import Character from '../../assets/images/character.png'

export function HomePage() {
  const history = useHistory()

  function rankings() {
    history.replace('/rankings')
  }

  function newResult() {
    history.push('/new-result')
  }

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-80 sm:w-96 mx-auto z-10"
            src={Challenge}
            alt="体力診断ゾーン"
          />
        </div>
      </header>

      <div className="flex flex-col items-center">
        <Button className="pb-3" onClick={rankings} size={Button.size.xl}>
          <div>
            ランキングを
            <ruby>
              見<rt>み</rt>
            </ruby>
            る
          </div>
        </Button>
        <Divider className="w-3/4 my-4" title="または" />
        <Button className="pb-3" onClick={newResult} size={Button.size.xl}>
          <div>
            <ruby>
              記録<rt>きろく</rt>
            </ruby>
            の
            <ruby>
              登録<rt>とうろく</rt>
            </ruby>
          </div>
        </Button>
      </div>
    </TochigiLayout>
  )
}
