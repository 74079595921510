import { useMemo, useRef, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import { useForm } from 'react-hook-form'

import {
  Badge,
  Button,
  Input,
  SelectMenu,
  Table,
} from '@sergeimeza/uikit-react'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  useDeleteChallengeResult,
  useFetchChallenges,
  usePostChallengeResult,
} from '../../providers/TochigiProvider'

import { TochigiLayout } from '../layout/TochigiLayout'

import Challenge from '../../assets/images/challenge.png'
import Character from '../../assets/images/character.png'
import { TableColumnData } from '@sergeimeza/uikit-react/lib/Elements/Table/Table'

let resultSchema = yup
  .object({
    testYear: yup.number().min(2022).max(2100).required(),
    period: yup.number().min(1).max(2).required(),
    gender: yup.string(),
    testDate: yup.string().required(),
    value: yup.number().min(0).max(1000).required(),
    schoolName: yup.string().min(4).max(100).required(),
    schoolGrade: yup.number().min(1).max(9).required(),
    schoolClass: yup.number().min(1).max(9).required(),
    teamName: yup.string().min(1).max(100).required(),
    membersCount: yup.number().min(1).max(1000).required(),
    uploaderName: yup.string().max(100).optional(),
    uploaderContactEmail: yup.string().email().max(100).optional(),
    uploaderContactPhone: yup.string().max(100).optional(),
  })
  .required()

export function NewResultPage() {
  const { challengeId }: any = useParams()
  const { challenges, loading } = useFetchChallenges()
  const layoutRef = useRef<any>(null)

  /**
   * @example
   * const challenge = {
   *   _id: '6173cb1ca2c279e847d1f0cc',
   *   challengeType: 'GROUP',
   *   challengeName: 'なわとびチャレンジ',
   *   challengeUnit: '',
   * }
   */
  const challenge = useMemo(() => {
    if (!challenges) return
    return challenges.find((challenge) => challenge._id === challengeId)
  }, [challenges])

  const [payload, setPayload] = useState<any>(null)
  const [showConfirmationSection, setShowConfirmationSection] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resultSchema),
  })

  const { postChallengeResult } = usePostChallengeResult()

  async function onSubmit(data: any) {
    try {
      let payload = {
        ...data,
        membersCount:
          challenge.challengeType === 'GROUP' ? data.membersCount : 1,
        teamName:
          challenge.challengeType === 'GROUP'
            ? data.teamName
            : isNaN(+data.teamName)
            ? 0
            : +data.teamName,
      }
      let result = await postChallengeResult(challengeId, payload)
      setPayload(result)
      setShowConfirmationSection(true)
    } catch (error) {
      console.log(error)
    }

    layoutRef.current.scrollIntoView()
  }

  if (loading) return null
  if (!challenge) return null

  let challengeForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="border border-primary-500 overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-4">
              <SelectMenu
                label="年度"
                name="testYear"
                selectClassNames="bg-primary-100 border border-primary-400"
                options={[
                  // { name: '2022', value: 2022 },
                  // { name: '2023', value: 2023 },
                  { name: '2024', value: 2024 },
                  // { name: '2025', value: 2025 },
                  // { name: '2026', value: 2026 },
                  // { name: '2027', value: 2027 },
                  // { name: '2028', value: 2028 },
                  // { name: '2029', value: 2029 },
                  // { name: '2030', value: 2030 },
                ]}
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                defaultValue={2024}
                register={register('testYear')}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <SelectMenu
                label="期間"
                name="period"
                selectClassNames="bg-primary-100 border border-primary-400"
                options={[
                  { name: '前期', value: 1 },
                  { name: '後期', value: 2 },
                ]}
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                selectedItem={{
                  name: `前期`,
                  value: 1,
                }}
                defaultValue={2}
                register={register('period')}
              />
            </div>
            {challenge.challengeType === 'INDIVIDUAL' && (
              <div className="col-span-6 sm:col-span-4">
                <SelectMenu
                  label="性別"
                  name="gender"
                  selectClassNames="bg-primary-100 border border-primary-400"
                  options={[
                    { name: '男子', value: 'MALE' },
                    { name: '女子', value: 'FEMALE' },
                  ]}
                  cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                  selectedItem={{ name: '男子', value: 'MALE' }}
                  register={register('gender')}
                />
              </div>
            )}
            <div className="col-span-6 sm:col-span-4">
              <Input
                type="number"
                {...(challenge?.challengeName === 'なわとびチャレンジ'
                  ? { min: 0, step: 1 }
                  : { step: 0.1 })}
                description="単位は「秒」の場合は、小数点第一まで入力可能です。"
                label={`記録入力（単位: ${challenge.challengeUnit}）`}
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('value')}
                error={errors.value?.message && '入力を確認してください'}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <Input
                type="text"
                label="学校名"
                description="記入は必ず市町から入力してください　例）〇〇市立とちまる小学校"
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('schoolName')}
                error={errors.schoolName?.message && '入力を確認してください'}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <SelectMenu
                label="学年"
                name="schoolGrade"
                selectClassNames="bg-primary-100 border border-primary-400"
                options={[
                  { name: '小学校 1年生', value: 1 },
                  { name: '小学校 2年生', value: 2 },
                  { name: '小学校 3年生', value: 3 },
                  { name: '小学校 4年生', value: 4 },
                  { name: '小学校 5年生', value: 5 },
                  { name: '小学校 6年生', value: 6 },
                ]}
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                selectedItem={{ name: '小学校 1年生', value: 1 }}
                register={register('schoolGrade')}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <Input
                type="number"
                label="組"
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('schoolClass')}
                description="半角数字で入力してください"
                error={errors.schoolClass?.message && '入力を確認してください'}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <Input
                type={challenge.challengeType === 'GROUP' ? 'text' : 'number'}
                label={
                  challenge.challengeType === 'GROUP'
                    ? 'グループ名（6年1組、　6年○○チーム　など）'
                    : '出席番号'
                }
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('teamName')}
                description={
                  challenge.challengeType === 'GROUP'
                    ? ''
                    : '半角数字で入力してください'
                }
                error={errors.teamName?.message && '入力を確認してください'}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <Input
                type="number"
                label="人数"
                description="ひとりでチャレンジランキングの場合は、1と入力してください。"
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('membersCount')}
                error={errors.membersCount?.message && '入力を確認してください'}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <Input
                type="text"
                label="記録入力者名"
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('uploaderName')}
                error={errors.uploaderName?.message && '入力を確認してください'}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <Input
                type="date"
                label="測定日"
                cornerHint={<Badge shape={Badge.shape.default}>必須</Badge>}
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('testDate')}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <Input
                type="text"
                label="学校メールアドレス"
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('uploaderContactEmail')}
                error={
                  errors.uploaderContactEmail?.message &&
                  '入力を確認してください'
                }
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <Input
                type="text"
                label="学校電話番号"
                inputClassNames="bg-primary-100 border border-primary-400"
                register={register('uploaderContactPhone')}
                error={
                  errors.uploaderContactPhone?.message &&
                  '入力を確認してください'
                }
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <div className="space-x-4">
            <Button
              className="inline-flex justify-center"
              type="submit"
              title="登録"
              size={Button.size.xl}
            />
          </div>
        </div>
      </div>
    </form>
  )

  return (
    <TochigiLayout>
      <div ref={layoutRef}></div>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-80 sm:w-96 mx-auto z-10"
            src={Challenge}
            alt="体力診断ゾーン"
          />
        </div>
      </header>
      <h1 className="text-lg sm:text-2xl text-primary-700 tracking-widest font-bold mb-4">
        {challenge?.challengeName}
      </h1>
      {showConfirmationSection ? (
        <ConfirmationSection
          setShowConfirmationSection={setShowConfirmationSection}
          challengeId={challengeId}
          challengeType={challenge.challengeType}
          result={payload}
        />
      ) : (
        challengeForm
      )}
    </TochigiLayout>
  )
}

const ConfirmationSection: React.FC<any> = ({
  challengeId,
  challengeType,
  result,
  setShowConfirmationSection,
}) => {
  const history = useHistory()
  const { deleteChallengeResult } = useDeleteChallengeResult()

  const challengeResultColumns: TableColumnData[] = [
    {
      key: 'key',
      label: 'key',
      value: ['key'],
      labelHidden: true,
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      key: 'value',
      label: 'value',
      value: ['value'],
      labelHidden: true,
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
  ]

  return (
    <div className="bg-white px-4 py-5 rounded-md">
      <h2 className="font-medium text-gray-900 text-2xl tracking-widest mb-4">
        情報の確認
      </h2>

      <div>
        <Table
          data={[
            {
              key: '年度',
              value: result.testYear,
            },
            ...(challengeType === 'INDIVIDUAL'
              ? [
                  {
                    key: '性別',
                    value: result.gender === 'MALE' ? '男性' : '女性',
                  },
                ]
              : []),
            {
              key: '記録入力',
              value: result.value,
            },
            {
              key: '学校名',
              value: result.schoolName,
            },
            {
              key: '学年',
              value: `${result.schoolGrade} 学年`,
            },
            {
              key: '組',
              value: `${result.schoolClass} 組`,
            },
            {
              key: 'グループ名 / 出席番号',
              value:
                challengeType === 'GROUP'
                  ? result.teamName
                  : `${result.teamName}番`,
            },
            {
              key: '人数',
              value: `${result.membersCount} 人`,
            },
            {
              key: '記録入力者名',
              value: result.uploaderName,
            },
            {
              key: '測定日',
              value: result.testDate,
            },
            {
              key: '学校メールアドレス',
              value: result.uploaderContactEmail,
            },
            {
              key: '学校電話番号',
              value: result.uploaderContactPhone,
            },
          ]}
          columns={challengeResultColumns}
        />
      </div>

      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex flex-row justify-end rounded-sm mt-4">
        <div className="flex flex-row space-x-4">
          <Button
            buttonType={Button.buttonType.secondary}
            onClick={async () => {
              await deleteChallengeResult(challengeId, result._id)
              setShowConfirmationSection(false)
            }}
          >
            <div>修正する</div>
          </Button>
          <Button
            onClick={() => {
              alert('記録を登録しました')
              history.push('/rankings')
            }}
          >
            <div>登録する</div>
          </Button>
        </div>
      </div>
    </div>
  )
}
