import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { TochigiProvider } from './providers/TochigiProvider'

import { NotFoundPage } from './components/pages/NotFoundPage'
import { HomePage } from './components/pages/HomePage'
import { RankingPage } from './components/pages/RankingPage'
import { RankingsPage } from './components/pages/RankingsPage'
import { NewResultsPage } from './components/pages/NewResultsPage'
import { NewResultPage } from './components/pages/NewResultPage'

import Protect from './components/layout/Protect'

function App() {
  return (
    <TochigiProvider>
      <Router>
        <Switch>
          {/* rankings系 */}
          <Route path="/rankings">
            <Switch>
              <Route path="/rankings/:challengeId">
                <RankingPage />
              </Route>
              <Route exact path="/rankings">
                <RankingsPage />
              </Route>
            </Switch>
          </Route>
          <Route path="/new-result/:challengeId">
            <NewResultPage />
          </Route>
          <Route path="/new-result">
            {/* tochigi2024 */}
            <Protect
              sha512="317cfd5f863a72eaf6bb9fc8fd95ce356ee0040278c1ae43f98a1bd8d5392ba699597ef50dd0f730170df89c4d95d4ea497cc0c27244df8b59778e5b9e9f2214"
              boxTitle="パスワードを入力してください。"
              inputPlaceholder="パスワード"
              buttonLabel="送信"
              authenErrorMessage="パスワードが正しくありません"
              blur={true}
            >
              <NewResultsPage />
            </Protect>
          </Route>
          <Route exact path="/">
            {/* tochigi2024 */}
            <Protect
              sha512="317cfd5f863a72eaf6bb9fc8fd95ce356ee0040278c1ae43f98a1bd8d5392ba699597ef50dd0f730170df89c4d95d4ea497cc0c27244df8b59778e5b9e9f2214"
              boxTitle="パスワードを入力してください。"
              inputPlaceholder="パスワード"
              buttonLabel="送信"
              authenErrorMessage="パスワードが正しくありません"
              blur={true}
            >
              <HomePage />
            </Protect>
          </Route>
          <Route path="/">
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
    </TochigiProvider>
  )
}

export default App
