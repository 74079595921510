import BGImageSmall from '../../assets/images/bg-sp.jpg'
import BGImage from '../../assets/images/bg.jpg'
import Logo from '../../assets/images/logo.png'
import GoToTop from '../../assets/images/link-top.png'

import ChallengeText from '../../assets/images/text-challenge.png'
import HappyKidsText from '../../assets/images/text-genkikids.png'
import LinkText from '../../assets/images/text-link.png'
import MoviesText from '../../assets/images/text-movies.png'
import NewsText from '../../assets/images/text-news.png'
import TestText from '../../assets/images/text-shindan.png'
import FitnessText from '../../assets/images/text-tairyoku.png'
import { Button } from '@sergeimeza/uikit-react'

import { useHistory } from 'react-router-dom'

export const TochigiLayout: React.FC<any> = ({ children }) => {
  const history = useHistory()

  const RightSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-7 w-7 text-white absolute right-2"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
        clipRule="evenodd"
      />
    </svg>
  )

  let menu = (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
        <a href="https://www.tochigi-rising-kids.jp/news/">
          <div className="relative h-12 xl:h-16 flex items-center justify-center bg-red-400 py-3 px-2 rounded-tl-lg md:rounded-tl-xl rounded-tr-lg md:rounded-tr-none">
            <img className="h-7 md:h-8" src={NewsText} alt="" />
            <RightSVG />
          </div>
        </a>
        <a href="https://www.tochigi-rising-kids.jp/tairyoku/">
          <div className="relative h-12 xl:h-16 flex items-center justify-center bg-orange-400 py-3 px-2 rounded-tr-none md:rounded-tr-xl xl:rounded-tr-none">
            <img className="h-7 md:h-7" src={FitnessText} alt="" />
            <RightSVG />
          </div>
        </a>
        <a href="https://www.tochigi-rising-kids.jp/shindan/">
          <div className="relative h-12 xl:h-16 flex items-center justify-center bg-yellow-400 py-3 px-2">
            <img className="h-7 md:h-8" src={TestText} alt="" />
            <RightSVG />
          </div>
        </a>
        <a href="https://www.tochigi-rising-kids.jp/challenge/">
          <div className="relative h-12 xl:h-16 flex items-center justify-center bg-green-500 py-3 px-2 rounded-tr-none md:rounded-tr-none xl:rounded-tr-xl">
            <img className="h-7 md:h-8" src={ChallengeText} alt="" />
            <RightSVG />
          </div>
        </a>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        <a href="https://www.tochigi-rising-kids.jp/genkikids/">
          <div className="relative h-12 xl:h-16 flex items-center justify-center bg-sky-400 py-3 px-2 rounded-bl-none md:rounded-bl-none xl:rounded-bl-xl">
            <img className="h-7 md:h-8" src={HappyKidsText} alt="" />
            <RightSVG />
          </div>
        </a>
        <a href="https://www.tochigi-rising-kids.jp/movies/">
          <div className="relative h-12 xl:h-16 flex items-center justify-center bg-blue-500 py-3 px-2 rounded-br-none md:rounded-br-xl xl:rounded-br-none">
            <img className="h-7 md:h-8" src={MoviesText} alt="" />
            <RightSVG />
          </div>
        </a>
        <a href="https://www.tochigi-rising-kids.jp/link/">
          <div className="relative h-12 xl:h-16 flex items-center justify-center bg-fuchsia-500 py-3 px-2 rounded-bl-lg md:rounded-bl-xl xl:rounded-bl-none rounded-br-lg md:rounded-br-xl">
            <img className="h-7 md:h-8" src={LinkText} alt="" />
            <RightSVG />
          </div>
        </a>
      </div>
    </>
  )

  return (
    <div>
      <img
        className="hidden sm:block object-cover h-full w-full mx-auto absolute z-0"
        src={BGImage}
        alt=""
      />
      <img
        className="block sm:hidden object-cover h-full w-full mx-auto absolute z-0"
        src={BGImageSmall}
        alt=""
      />

      <div className="h-screen relative overflow-y-scroll">
        <div className="flex flex-col justify-between min-h-screen w-full container mx-auto relative z-10 py-10">
          <div>
            <div className="flex flex-col items-center space-y-5 sm:space-y-10">
              <div className="logo-drop-shadow">
                <a href="https://www.tochigi-rising-kids.jp" target="_self">
                  <img
                    className="w-72 sm:w-96 mx-auto z-10"
                    src={Logo}
                    alt=""
                  />
                </a>
              </div>

              <div className="hidden md:block w-full px-5 sm:px-4">{menu}</div>

              <div className="w-full px-2 sm:px-5">
                <div className="w-full relative bg-white-transparent rounded-lg max-w-4xl lg:max-w-full mx-auto px-2 sm:px-8 pt-2 pb-40 sm:pt-12 shadow-lg border border-blue-100 overflow-x-auto">
                  {children}
                </div>
              </div>
            </div>

            <div className="block md:hidden w-full px-5 sm:px-4 mt-10">
              {menu}
            </div>

            <div className="z-10 flex justify-between mx-0 sm:mx-10 mt-10">
              <div>
                <Button
                  size={Button.size.xxl}
                  title="戻る"
                  onClick={() => history.goBack()}
                />
              </div>

              <a href="https://www.tochigi-rising-kids.jp/">
                <img className="w-24 sm:w-36" src={GoToTop} alt="" />
              </a>
            </div>
          </div>

          <div className="mt-10">
            <div className="flex flex-col items-center mx-auto">
              <div className="flex flex-col md:flex-row  md:space-x-10 space-y-4 md:space-y-0">
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/contact/"
                >
                  お問い合わせ
                </a>
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/#"
                >
                  ご利用について
                </a>
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/privacy/"
                >
                  個人情報の取り扱いについて
                </a>
              </div>
            </div>
            <div className="mt-4 text-center mb-8 mx-auto">
              <span className="text-sm text-white tracking-widest">
                ©栃木県教育委員会事務局スポーツ振興課
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
